import React, { useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, Button, FormLabel, makeStyles, TextField, Typography, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useAppSelector } from 'store';
import { isEqual } from 'lodash';
import { CommonSelectorOptionType, SearchBar } from 'common';
import {
  fetchGroovVoicePrompts,
  initialFilterState,
  resetPromptFilters,
  setFilters,
  setSearchFilteredPrompts,
  setSearchQuery,
  toggleState,
  toggleType,
  exportToCSV,
} from '../../GroovVoice.slice';
import { PromptItemType } from '../../GroovVoice.d';
import { PromptTags } from '../Common/PromptTags';
import { SearchAndSelect } from '../Common/SearchAndSelect';
import usePromptAttributes from 'pages/PromptBuilder/usePromptAttributes';
import { getEndOfCurrentDay, getUniqueTags, promptTypes } from '../../GroovVoice.utils';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: '1px solid',
  },
  innerBox: {
    padding: theme.spacing(2),
  },
  sectionBox: {
    padding: theme.spacing(1),
  },
  label: {
    color: '#000',
  },
  dateField: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  selectField: {
    height: 40,
    marginTop: theme.spacing(1),
  },
  chip: {
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
}));

export function ListFilters() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { categoryOptions } = usePromptAttributes();
  const { filteredPrompts, prompts, searchQuery, filters, isExporting } = useAppSelector(
    (state) => ({
      filteredPrompts: state.groovVoice.filteredPrompts,
      prompts: state.groovVoice.prompts,
      searchQuery: state.groovVoice.searchQuery,
      filters: state.groovVoice.filters,
      isExporting: state.groovVoice.isExporting,
    }),
    isEqual
  );

  useEffect(() => {
    const startTime = moment(filters.createdFrom);
    const endTime = moment(filters.createdTo);
    dispatch(fetchGroovVoicePrompts({ startTime, endTime }));
  }, [dispatch, filters.createdFrom, filters.createdTo]);

  const tags = useMemo(() => {
    return getUniqueTags(prompts);
  }, [prompts]);

  const records = useMemo(() => {
    if (
      !isEqual(
        { ...initialFilterState, createdFrom: '', createdTo: '' },
        { ...filters, createdFrom: '', createdTo: '' }
      )
    ) {
      return filteredPrompts;
    }
    return prompts;
  }, [filteredPrompts, filters, prompts]);

  const handleDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!e.target) return;
      const { name, value } = e.target;
      if (name === 'createdFrom') {
        dispatch(
          setFilters({ createdFrom: moment(value).startOf('day').format('YYYY-MM-DDTHH:mm:ss') })
        );
      }
      if (name === 'createdTo') {
        dispatch(
          setFilters({ createdTo: moment(value).endOf('day').format('YYYY-MM-DDTHH:mm:ss') })
        );
      }
    },
    [dispatch]
  );

  const handleSelectChange = useCallback(
    (name: string, value: CommonSelectorOptionType[]) => {
      dispatch(setFilters({ [name]: value }));
    },
    [dispatch]
  );

  const handleStateToggle = useCallback(
    (stateId: string) => {
      dispatch(toggleState(stateId));
    },
    [dispatch]
  );

  const handleTypeToggle = useCallback(
    (typeId: string) => {
      dispatch(toggleType(typeId));
    },
    [dispatch]
  );

  const handleSearchChange = useCallback(
    (records: PromptItemType[]) => {
      dispatch(setSearchFilteredPrompts(records));
    },
    [dispatch]
  );

  const handleQueryChange = useCallback(
    (query: string) => {
      dispatch(setSearchQuery(query));
    },
    [dispatch]
  );

  const handleResetFilters = useCallback(async () => {
    dispatch(resetPromptFilters());
  }, [dispatch]);

  const handleExportClick = useCallback(() => {
    const exportablePrompts = records
      .filter((prompt) => prompt.totalResponses > 0)
      .map((prompt) => ({
        promptId: Number(prompt.promptId),
        categoryId: String(prompt.categoryId),
      }));

    if (exportablePrompts.length > 0) {
      dispatch(exportToCSV(exportablePrompts));
    }
  }, [dispatch, records]);

  const hasExportablePrompts = records?.some((prompt) => prompt.totalResponses > 0);

  const today = getEndOfCurrentDay().format('YYYY-MM-DD');
  const renderDateField = (label: string, name: string, value: string) => (
    <Box display="flex" flexDirection="column">
      <FormLabel>{label}</FormLabel>
      <TextField
        type="date"
        variant="outlined"
        size="small"
        name={name}
        value={value}
        onChange={handleDateChange}
        className={classes.dateField}
        InputProps={{
          inputProps: {
            max: today,
          },
        }}
      />
    </Box>
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={classes.container}>
        <Box className={classes.innerBox} my={2}>
          <Typography variant="h3">Filters</Typography>
          <Box my={2}>
            <SearchBar
              records={records}
              searchKeys={['promptName', 'promptText']}
              fullWidth
              showSearchIcon
              size="small"
              setResults={(recs) => handleSearchChange(recs as PromptItemType[])}
              query={searchQuery}
              onQueryChange={handleQueryChange}
            />
          </Box>

          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>CREATED</FormLabel>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gridRowGap={8}>
              {renderDateField(
                'From',
                'createdFrom',
                moment(filters.createdFrom).format('YYYY-MM-DD')
              )}
              {renderDateField('To', 'createdTo', moment(filters.createdTo).format('YYYY-MM-DD'))}
            </Box>
          </Box>

          <Box className={classes.sectionBox} my={2} hidden>
            <FormLabel className={classes.label}>CATEGORY</FormLabel>
            <SearchAndSelect
              options={categoryOptions}
              value={filters.category}
              handleOnChange={(_, value) => handleSelectChange('category', value)}
            />
          </Box>

          <Box className={classes.sectionBox} my={2} hidden>
            <FormLabel className={classes.label}>SURVEY/PACK</FormLabel>
            <SearchAndSelect
              options={['Survey', 'Prompt'].map((o) => ({ id: o, label: o }))}
              value={filters.promptType}
              handleOnChange={(_, value) => handleSelectChange('promptType', value)}
            />
          </Box>

          <Box className={classes.sectionBox} my={2} hidden>
            <FormLabel className={classes.label}>STATE</FormLabel>
            <PromptTags
              tags={[
                { id: 'Active', label: 'Active' },
                { id: 'Inactive', label: 'Inactive' },
              ]}
              selectedTags={filters.states}
              onClick={handleStateToggle}
            />
          </Box>

          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>TYPE</FormLabel>
            <PromptTags
              tags={promptTypes}
              selectedTags={filters.types}
              onClick={handleTypeToggle}
            />
          </Box>

          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>TAGS</FormLabel>
            <SearchAndSelect
              options={tags.map((v) => ({ id: v, label: v }))}
              value={filters.tags}
              handleOnChange={(_, value) => handleSelectChange('tags', value)}
            />
          </Box>
          <Box className={classes.sectionBox} my={2}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Button variant="outlined" color="secondary" fullWidth onClick={handleResetFilters}>
                  Reset Filters
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  fullWidth
                  startIcon={<GetAppIcon />}
                  onClick={handleExportClick}
                  disabled={isExporting || !hasExportablePrompts}>
                  {isExporting ? 'Processing...' : 'Export to CSV'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
}
