import React from 'react';
import { styled } from '@material-ui/core/styles';
import MaterialCard from '@material-ui/core/Card';
import { colors } from 'styles/colors';
import { Box, Grid, Typography } from '@material-ui/core';
import { PromptItemType } from '../../GroovVoice';
import { PromptTags } from '../Common/PromptTags';
import { useHistory } from 'react-router-dom';
import { RoutePath } from 'types/routes';
import { AnalyticsInfo } from '../Common/AnalyticsInfo';
import { format } from 'date-fns';
import {
  getAndSetCurrentSelectedPromptDetails,
  getAndSetCurrentSelectedCampaignPromptDetails,
  getPromptTags,
} from '../../GroovVoice.utils';
import { updatePromptIdWithPrefix } from 'utils/string.utils';

const Card = styled(MaterialCard)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(4, 4),
  paddingRight: theme.spacing(0),
  margin: theme.spacing(4, 2),
  borderRadius: theme.spacing(3),
  background: colors.white,
  fontWeight: 'bold',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[400],
  '&:hover': {},
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export function PromptItem({ prompt }: Readonly<{ prompt: PromptItemType }>) {
  const { push } = useHistory();
  const handleCardPress = async () => {
    if (prompt.categoryId.toString() === '11') {
      await getAndSetCurrentSelectedCampaignPromptDetails(prompt.promptId);
    } else {
      await getAndSetCurrentSelectedPromptDetails(prompt.promptId);
    }
    push(`${RoutePath.GroovVoice}/${prompt.promptId}`);
  };

  const responseRate = prompt.responseRate * 100;

  const formattedRate = Number.isInteger(responseRate)
    ? responseRate.toString()
    : responseRate.toFixed(1);

  return (
    <Card onClick={handleCardPress}>
      <Box px={4}>
        <PromptTags tags={getPromptTags(prompt)} />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item style={{ width: '320px' }}>
            <Box pt={2}>
              <Typography variant="h4">{prompt.promptName}</Typography>
              <Typography variant="body2">
                {format(new Date(prompt.lastSentUtc), 'yyyy-MM-dd hh:mm a')}
              </Typography>
              <Box pt={2}>
                <Typography variant="body1">{updatePromptIdWithPrefix(prompt.promptId)}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Grid container spacing={2} direction="row">
              <Grid item>
                <AnalyticsInfo
                  label="Responses"
                  value={`${prompt.totalResponses}`}
                  subValue={`/${prompt.totalSent}`}
                />
              </Grid>
              <Grid item>
                <AnalyticsInfo label="Response Rate" value={formattedRate} subValue="%" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
