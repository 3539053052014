import { useState, useEffect } from 'react';
import { getPromptAttributes, PromptAttributeResponse } from './api';
import { CommonSelectorOptionType } from 'common';

const usePromptAttributes = () => {
  const [tags, setTags] = useState<CommonSelectorOptionType[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<CommonSelectorOptionType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    const fetchAttributes = async () => {
      setLoading(true);
      try {
        const response = await getPromptAttributes();
        if (!response.data) {
          return;
        }

        const attributes: PromptAttributeResponse = response.data;

        const tagOptions: CommonSelectorOptionType[] = attributes.tags.map((tag) => {
          return { id: tag.id.toString(), label: tag.name };
        });
        const categoryOptions: CommonSelectorOptionType[] = attributes.categories
          .filter((category) => {
            return category.id.toString() !== '11';
          })
          .map((category) => {
            return {
              id: category.id.toString(),
              label: category.display_name,
              sort: parseInt(category.sort),
            };
          })
          .sort((a: CommonSelectorOptionType, b: CommonSelectorOptionType) => {
            if (!a.sort || !b.sort) {
              return a.label.localeCompare(b.label);
            }
            return a.sort - b.sort;
          });
        setCategoryOptions(categoryOptions);
        setTags(tagOptions);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchAttributes();
  }, []);

  return { tags, categoryOptions, loading, error };
};

export default usePromptAttributes;
