import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getEndOfCurrentDay,
  getRolling30Days,
  getGroovVoicePrompts,
  getAndSetCurrentSelectedPromptDetails,
  getGroovVoiceCampaignPrompts,
  getAndSetCurrentSelectedCampaignPromptDetails,
} from './GroovVoice.utils';
import { PromptDetails, PromptItemType, ExportCampaignReportCSVDtoV1 } from './GroovVoice.d';
import { RootState } from 'store/rootReducer';
import axios, { AxiosRequestConfig } from 'axios';
import moment, { Moment } from 'moment';
import { dispatch } from 'store';
import { CommonSelectorOptionType } from 'common';
import { ApiRequest } from 'services/request';

const BASE_SERVICE_URL = process.env.REACT_APP_SERVICE_URL;

export interface GroovVoiceState {
  prompts: PromptItemType[];
  filteredPrompts: PromptItemType[];
  searchFilteredPrompts: PromptItemType[];
  loading: boolean;
  error: string | null;
  searchQuery: string;
  isExporting: boolean;
  filters: {
    createdFrom: string;
    createdTo: string;
    promptType: CommonSelectorOptionType[];
    states: string[];
    types: string[];
    tags: CommonSelectorOptionType[];
    category: CommonSelectorOptionType[];
  };
  detailScreenFilters: DetailScreenFilters;
  currentSelectedPromptDetails?: PromptDetails;
  isApplyingFilters?: boolean;
}

export type DetailScreenFilters = {
  manager: CommonSelectorOptionType[];
  officeLocation: CommonSelectorOptionType[];
  department: CommonSelectorOptionType[];
  jobTitle: CommonSelectorOptionType[];
};

export const initialFilterState = {
  states: ['Active'],
  types: [],
  createdFrom: getRolling30Days().format('YYYY-MM-DDTHH:mm:ss'),
  createdTo: getEndOfCurrentDay().format('YYYY-MM-DDTHH:mm:ss'),
  promptType: [],
  category: [],
  tags: [],
};

export const initialDetailsScreenFilterState = {
  manager: [],
  officeLocation: [],
  department: [],
  jobTitle: [],
};
const initialState: GroovVoiceState = {
  prompts: [],
  filteredPrompts: [],
  searchFilteredPrompts: [],
  loading: false,
  error: null,
  searchQuery: '',
  isExporting: false,
  filters: initialFilterState,
  detailScreenFilters: initialDetailsScreenFilterState,
  isApplyingFilters: false,
};

export const fetchGroovVoicePrompts = createAsyncThunk(
  'groovVoice/fetchPrompts',
  async ({ startTime, endTime }: { startTime?: Moment; endTime?: Moment }, { rejectWithValue }) => {
    try {
      const promptResponse = await getGroovVoicePrompts(startTime, endTime);
      const campaignPromptResponse = await getGroovVoiceCampaignPrompts(startTime, endTime);
      const prompts = [...promptResponse.data, ...campaignPromptResponse.data].sort((a, b) => {
        return new Date(b.lastSentUtc).valueOf() - new Date(a.lastSentUtc).valueOf();
      }) as PromptItemType[];

      return prompts;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response ? err.response.data : err.message);
      } else if (err instanceof Error) {
        console.error(`General error: ${err.name} - ${err.message}`);
        return [];
      } else {
        console.error(`An unknown error occurred: ${JSON.stringify(err)}`);
        return [];
      }
    }
  }
);

export const applyDetailsScreenFilters = createAsyncThunk(
  'groovVoice/applyDetailsScreenFilters',
  async (filters: DetailScreenFilters, { getState, rejectWithValue }) => {
    try {
      dispatch(setIsApplyingFilters(true));
      const rootState = getState() as RootState;

      const state = rootState?.groovVoice;
      dispatch(setDetailsScreenFilters(filters));

      if (state.currentSelectedPromptDetails?.prompt.categoryId == '11') {
        await getAndSetCurrentSelectedCampaignPromptDetails(
          state.currentSelectedPromptDetails?.prompt.id as unknown as string,
          filters
        );
      } else {
        await getAndSetCurrentSelectedPromptDetails(
          state.currentSelectedPromptDetails?.prompt.id as unknown as string,
          filters
        );
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response ? err.response.data : err.message);
      } else if (err instanceof Error) {
        console.error(`General error: ${err.name} - ${err.message}`);
      } else {
        console.error(`An unknown error occurred: ${JSON.stringify(err)}`);
      }
    }
  }
);

const applyFilters = (prompts: PromptItemType[], filters: GroovVoiceState['filters']) => {
  return prompts?.filter((prompt) => {
    const matchesType =
      filters.types.length === 0 || filters.types.includes(prompt.properties?.prompt_cta_type);

    const matchesPromptCategory =
      filters.category.length === 0 ||
      filters.category.filter((category) => prompt.categoryId.toString() === category.id.toString())
        .length > 0;

    const matchesTags =
      filters.tags.length === 0 ||
      prompt.tags?.some((ptag) => filters.tags.some((tag) => ptag === tag.id));

    return matchesType && matchesTags && matchesPromptCategory;
  });
};

const convertToCSV = (data: ExportCampaignReportCSVDtoV1[]): string => {
  // Headers
  const headers = [
    'Date Sent',
    'Prompt ID',
    'User ID',
    'User Name',
    'Manager Name',
    'Office Location',
    'Department',
    'Prompt',
    'Response',
    'Follow Up Response',
  ];

  // Transform data into rows
  const rows = data.flatMap((report) => {
    return report.responses.map((response) => [
      report.lastSentUtc ? moment(report.lastSentUtc).format('DD-MM-YYYY') : '',
      report.prompt.id || '',
      response.userId || '',
      response.username || '',
      response.demographics?.manager || '',
      response.demographics?.officeLocation || '',
      response.demographics?.department || '',
      (report.prompt.prompt || '').replace(/\n/g, '\\n'),
      (response.text || '').replace(/\n/g, '\\n'),
      (response.followUpText || '').replace(/\n/g, '\\n'),
    ]);
  });

  // Combine headers and rows
  const csvContent = [headers, ...rows]
    .map((row) =>
      row
        .map((cell: string | number) => {
          // Escape special characters and wrap in quotes if needed
          const escaped = String(cell).replace(/"/g, '""');
          return /[,"]/.test(escaped) ? `"${escaped}"` : escaped;
        })
        .join(',')
    )
    .join('\n');

  return csvContent;
};

export const exportToCSV = createAsyncThunk(
  'groovVoice/exportToCSV',
  async (prompts: Array<{ promptId: number; categoryId: string }>, { rejectWithValue }) => {
    try {
      const campaignPromptIds = prompts.filter((p) => p.categoryId === '11').map((p) => p.promptId);

      const conversationPromptIds = prompts
        .filter((p) => p.categoryId !== '11')
        .map((p) => p.promptId);

      const apiCalls = [];

      if (campaignPromptIds.length > 0) {
        const campaignsConfig: AxiosRequestConfig = {
          baseURL: BASE_SERVICE_URL,
          url: 'campaigns/v1/campaigns/reports/export-to-csv',
          method: 'POST',
          data: {
            campaignIds: campaignPromptIds,
          },
        };
        apiCalls.push(ApiRequest(campaignsConfig));
      }

      if (conversationPromptIds.length > 0) {
        const conversationConfig: AxiosRequestConfig = {
          baseURL: BASE_SERVICE_URL,
          url: 'conversation/partner/prompts/report/export-to-csv',
          method: 'POST',
          data: {
            promptIds: conversationPromptIds,
          },
        };
        apiCalls.push(ApiRequest(conversationConfig));
      }

      if (apiCalls.length === 0) {
        return rejectWithValue('No prompts selected for export');
      }

      const responses = await Promise.all(apiCalls);
      const combinedData = responses.reduce((acc, response) => {
        return [...acc, ...(response.data || [])];
      }, []);

      const csvContent = convertToCSV(combinedData);

      // Create a download link and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `groov-voice-export-${moment().format('YYYY-MM-DD')}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      return true;
    } catch (err: unknown) {
      console.error('Error exporting CSV:', err);
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data?.message || err.message;
        return rejectWithValue(`Failed to export CSV: ${errorMessage}`);
      }
      return rejectWithValue('Failed to export CSV. Please try again.');
    }
  }
);

const slice = createSlice({
  name: 'groovVoice',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const newFilters = { ...state.filters, ...action.payload };
      state.filters = newFilters;
      state.filteredPrompts = applyFilters(state.prompts, newFilters);
    },
    setDetailsScreenFilters: (state, action) => {
      const newFilters = { ...state.detailScreenFilters, ...action.payload };
      state.detailScreenFilters = newFilters;
    },
    resetDetailsScreenFilters: (state) => {
      state.detailScreenFilters = initialDetailsScreenFilterState;
    },
    setIsApplyingFilters: (state, action) => {
      state.isApplyingFilters = action.payload;
    },
    toggleState: (state, action) => {
      const stateId = action.payload;
      if (state.filters.states.includes(stateId)) {
        state.filters.states = state.filters.states.filter((id) => id !== stateId);
      } else {
        state.filters.states.push(stateId);
      }
      state.filteredPrompts = applyFilters(state.prompts, state.filters);
    },
    toggleType: (state, action) => {
      const typeId = action.payload;
      if (typeId && state.filters.types.includes(typeId)) {
        state.filters.types = state.filters.types.filter((id) => id !== typeId);
      } else {
        state.filters.types.push(typeId);
      }
      state.filteredPrompts = applyFilters(state.prompts, state.filters);
    },
    toggleCategory: (state, action) => {
      const typeId = action.payload;
      if (state.filters.types.includes(typeId)) {
        state.filters.types = state.filters.types.filter((id) => id !== typeId);
      } else {
        state.filters.types.push(typeId);
      }
      state.filteredPrompts = applyFilters(state.prompts, state.filters);
    },
    setSearchFilteredPrompts: (state, action) => {
      state.searchFilteredPrompts = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setCurrentSelectedPromptDetails: (state, action) => {
      state.currentSelectedPromptDetails = action.payload;
      state.isApplyingFilters = false;
    },
    resetPromptFilters: (state) => {
      state.filters = initialFilterState;
      state.filteredPrompts = applyFilters(state.prompts, state.filters);
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroovVoicePrompts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGroovVoicePrompts.fulfilled, (state, action) => {
        state.loading = false;
        state.prompts = action.payload;
        state.filteredPrompts = applyFilters(action.payload, state.filters);
      })
      .addCase(fetchGroovVoicePrompts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(exportToCSV.pending, (state) => {
        state.isExporting = true;
      })
      .addCase(exportToCSV.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(exportToCSV.rejected, (state) => {
        state.isExporting = false;
      });
  },
});

export const getGroovVoiceFilters = (state: RootState) => {
  return state?.groovVoice.filters;
};
export const { reducer: groovVoiceReducer, actions } = slice;
export const {
  setFilters,
  toggleState,
  toggleType,
  setSearchFilteredPrompts,
  setSearchQuery,
  setDetailsScreenFilters,
  resetDetailsScreenFilters,
  setCurrentSelectedPromptDetails,
  resetPromptFilters,
  setIsApplyingFilters,
} = actions;

export default slice;
